
/* PRELOADER CSS */
.page-loaderPaymentGateway {
  width: 100%;
  height: 100vh;
  background-color: #FFFFFF;
  position: fixed;
  z-index: 1000;
}

.page-loaderPaymentGateway .txtPaymentGateway {
  color: #005968;
  text-align: center;
  top: 40%;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-weight: bold;
  line-height: 1.5;
}

/* SPINNER ANIMATION */
.spinnerPaymentGateway {
  position: relative;
  top: 35%;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background-color: #005968;
  border-radius: 100%;  
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% { -webkit-transform: scale(0) }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}