@media(max-width:767px){
    .set-on-devices{
        width:100%!important;
    }
    .mob-pop{
        width:100%;
    }
}
@media(min-width:768px) and (max-width:1023px){
    .set-on-devices{
        width:100%!important;
    }
}
.set-on-devices{
    padding: 30px 68px 34px 68px;
}
.mob-pop{
        width: 435px;
    }