
/* PRELOADER CSS */
.page-loaderPayment {
    width: 100%;
    height: auto;
    background-color: #FFFFFF;
    position: absolute;
    top: 32%;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
  
  .page-loaderPayment .txtPayment {
    color: #005968;
    text-align: center;
    width: 40%;
    padding: 135px 0px;
    margin: 0 auto;
    display: block;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: bold;
    line-height: 1.5;
  }
  .txtPayment .MuiAlert-icon{
    display: block;
  }
  @media(max-width:767px){
    .page-loaderPayment .txtPayment {
      width: 80%;
      padding: 100px 0px;
    }
    .page-loaderPayment {
      bottom: 23%;
    }
  }
